code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html {
  background: url("./images/background.jpg") #f3f3f3 repeat;
  color: #1f4170;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

body,
button,
html,
input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif;
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

p {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 20px;
  }
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5 {
  color: #1f4170 !important;
  /* font-family: "Visitor-TT1-BRK"; */
  font-weight: 700 !important;
  margin: 0;
  margin-bottom: 40px !important;
}

h1 {
  font-size: 24px !important;

  @media only screen and (min-width: 768px) {
    font-size: 48px !important;
  }
}

h2 {
  font-size: 28px !important;

  @media only screen and (min-width: 768px) {
    font-size: 36px !important;
  }
}

span {
  display: inline;
}

strong {
  font-weight: 800;
}

.chakra-ui-light {
  background: transparent;
}

.MuiTypography-h6 {
  color: #fff !important;
}
